import React, { Component } from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import _ from 'lodash'
import { Link, navigate } from "gatsby"
import lang from '../../langs'
import { connect } from 'react-redux'
import { verifyForgotPassword, resetPassword } from '../../actions/auth';
import { resetPasswordValidate } from '../../validations/authValidate';
import { createNotification, pageTitle } from '../../utils/helpers'
import Layout from '../../components/layout'
import SEO from "../../components/seo"
import Logo from "../../components/Common/LogoDark"
import Spinner from "../Spinner"

class ResetPassword extends Component {
    constructor(props) {
        super(props);
        this.state = {
            new:'',
            confirm:'',
            checking:true,
            loading:false,
            success:false,
            errors: {}
        };

        this.onChange = this.onChange.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
        this.passwordCode = ''
    }
    componentDidMount() {
        if (this.props.auth.isAuthenticated) {
            navigate('/user/dashboard');
        }

        const hash = this.props.location.hash
        const code = hash.replace("#", "");
        this.passwordCode = code
        this.props.verifyForgotPassword(code)
            .then(res=>{
                this.setState({
                    checking:false
                })
            })
            .catch(err=>{
                const resp = err.response
                if( resp.data.message == 'USER_NOT_EXISTS' ) {
                   navigate(`/404`, {
                       state: {
                           message:"The link might be expired or no exists"
                       }
                   })
                }
            })
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.auth.isAuthenticated) {
            navigate('/user/dashboard');
        }
    }

    async onSubmit(e) {
        e.preventDefault();
        const userData = {
            new: this.state.new,
            confirm: this.state.confirm
        };

        try {
            await resetPasswordValidate(userData)
        } catch (err) {
            this.setState({ errors: err })
            createNotification('error', _.map(err, (val) => { return val }).join("\n\n\n"))
            return false;
        }

        this.setState({loading:true, errors:{}}, ()=>{
            this.props.resetPassword({
                new_password:userData.new,
                new_password_confirmation:userData.confirm,
                code:this.passwordCode
            }).then(res => {
                    this.setState({
                        success:true
                    })
                    setTimeout(() => {
                        navigate(`/login`, {
                            state:{
                                resetSuccess:true
                            }
                        })
                    }, 2000);
                }).catch(err => {
                    if (err && typeof err.response !== undefined) {
                        const error = err.response
                        if (_.has(error, 'status') && (error.status == 400 ||
                            error.status == 401 ||
                            error.status == 422)) {
                            createNotification('warning', error.data.message)
                        } else {
                            createNotification('error', lang('error.server_unknown'))
                        }
                    } else {
                        createNotification('error', lang('error.server_unknown'))
                    }
                    
                    this.setState({
                        loading:false
                    })
                })
        })
    }

    onChange(e) {
        this.setState({ [e.target.name]: e.target.value });

        if (this.state.errors) {
            if (this.state.new && this.state.errors.new) {
                this.setState({ errors: { new: '' } })
            }

            if (this.state.confirm && this.state.errors.confirm) {
                this.setState({ errors: { confirm: '' } })
            }
        }
    }


    render() {
        const { errors } = this.state;

        if( this.state.checking == true ) {
            return (
                <Layout withHeader={false} withFooter={false}>
                    <div className="d-flex" style={{
                        justifyContent:"center",
                        alignContent:"center",
                        alignItems:"center",
                        textAlign:"center",
                        flexGrow:3,
                        height:400,
                        flexDirection:"column"
                    }}>
                        <Spinner />
                        <h2>Checking your link...</h2>
                    </div>
                </Layout>
            )
        }
        return (
            <Layout withHeader={false} withFooter={false}>
                <SEO title="Reset Password" />
                <div id="bodyReset">
                    <div style={{height:600, marginTop:100}} className="align-items-center align-content-center justify-content-center w-auto-xs bg-white">
                        <div className="row justify-content-md-center">
                            <div className="col-4">
                                <div className="shadow rounded p-5 mt-3 mb-3 align-content-center justify-content-center  align-items-center" style={{width:400}}>
                                    <div className="text-color">
                                        <div className="text-center">
                                            <Logo mode="dark" />
                                        </div>
                                    
                                        
                                        <form name="formReset" noValidate>
                                            {this.state.success == false ? (<>
                                                <div className="text-uppercase text-muted text-center mb-4 text-sm">
                                                    RESET PASSWORD FORM
                                                </div>
                                                <div className="alert alert-info">
                                                    <p>
                                                        Please enter new Password and confirm password. The Password must have consisted min. 8 characters
                                                    </p>
                                                </div>
                                                <div className="form-label-group mb-4">
                                                    <input
                                                        type="password"
                                                        name="new"
                                                        id="new"
                                                        placeholder="Enter new password"
                                                        className={classnames('form-control form-control-lg', {
                                                            'is-invalid': errors.new
                                                        })}
                                                        onChange={this.onChange}
                                                        value={this.state.new}
                                                    />
                                                    {errors.new && <div className="invalid-feedback">{errors.new}.</div>}
                                                </div>

                                                <div className="form-label-group mb-4">
                                                    <input
                                                        type="password"
                                                        name="confirm"
                                                        id="confirm"
                                                        placeholder="Enter confirm new password"
                                                        className={classnames('form-control form-control-lg', {
                                                            'is-invalid': errors.confirm
                                                        })}
                                                        onChange={this.onChange}
                                                        value={this.state.confirm}
                                                    />
                                                    {errors.confirm && <div className="invalid-feedback">{errors.confirm}.</div>}
                                                </div>
                                            
                                                <button type="button" id="resetSubmit" disabled={this.state.loading ? true : false} onClick={this.onSubmit} className="btn btn-danger btn-block btn-lg">{this.state.loading ? 'Submitting...' : 'Submit'}</button>
                                            </>) : (
                                                <>
                                                    <h4>Reset Password Done!</h4>
                                                    <div className="alert alert-info">
                                                        Great! You just successfully set up password with new password, please wait the page will be redirect to login page
                                                    </div>
                                                </>
                                            )}

                                            
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Layout>
        )
    }
}

ResetPassword.propTypes = {
    auth: PropTypes.object.isRequired,
    errors: PropTypes.object.isRequired,
    verifyForgotPassword: PropTypes.func.isRequired
}

const mapStateToProps = state => ({
    auth: state.auth,
    errors: state.errors,
    loaded: state.loadingBar.sectionBar == 1 ? true : false
});

export default connect(mapStateToProps, { verifyForgotPassword, resetPassword })(ResetPassword);

