import React from 'react'
import LoadingBar from 'react-redux-loading-bar'
import { NotificationContainer } from 'react-notifications'
import Layout from "../components/layout"
// Auth Component
import ResetPassword from '../components/Auth/ResetPassword'

const ResetPasswordIndex = ({location, pageContext}) => {
    
    return (
        <>
            <NotificationContainer />
            <LoadingBar scope="sectionBar" />
            <ResetPassword location={location} />
        </>
    );
}

export default ResetPasswordIndex